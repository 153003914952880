import { useState } from "react";
import type { BaseSyntheticEvent, ReactElement } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import CommuneSelect from "components/inputs/CommuneSelect";
import ControlledDateTime from "components/inputs/ControlledDateTime";
import ControlledOuvrageTypeSelect from "components/inputs/ControlledOuvrageTypeSelect";
import ControlledTextField from "components/inputs/ControlledTextField";
import ControlledTimePicker from "components/inputs/ControlledTimePicker";
import OuvrageSelect from "components/inputs/OuvrageSelect";
import { Controller, useForm } from "react-hook-form";
import type { SubmitHandler, FieldValues } from "react-hook-form";
import FormSectionTitle from "./FormSectionTitle";
import { useData } from "providers/DataProvider";
import type { DangerGraveFormModel } from "models/DangerGraveFormModel";
import ToastMessages from "constants/ToastMessages";
import type { DangerGrave, OfflineDangerGrave } from "models/DangerGrave";
import { useSnackbar } from "notistack";
import DividerTitle from "components/utils/DividerTitle";
import PhotosInput from "components/inputs/photos/PhotosInput";
import { useNavigate } from "react-router-dom";
import formModelService from "services/formModelService";
import type { IndexableType } from "dexie";
import declarationOfflineService from "services/declarationOfflineService";
import { Guid } from "guid-typescript";
import dangerService from "services/dangerService";
import LoadingButton from "components/buttons/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { dateUtil } from "@sdeapps/react-core";
import DeclarationConfidentielleCheckboxLabel from "constants/DeclarationFormCommonLabels";

interface DangerGraveFormProps {
  isNew?: boolean;
  dangerGrave?: DangerGrave;
}

function DangerGraveForm({
  isNew = true,
  dangerGrave,
}: Readonly<DangerGraveFormProps>): ReactElement {
  const isFormReadonly = false;
  const [disableSending, setDisableSending] = useState(false);
  const data = useData();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm({ shouldFocusError: false });

  async function onSubmit(formModel: DangerGraveFormModel): Promise<void> {
    setDisableSending(true);
    const newDangerGrave = formModelService.formModelToDangerGrave(formModel);
    await createDangerGrave(newDangerGrave);
    setDisableSending(false);
  }

  async function createDangerGrave(newDangerGrave: DangerGrave): Promise<void> {
    let offlineFailed: boolean = false;
    let offlineIndex: IndexableType | undefined;
    let offlineDeclaration: OfflineDangerGrave | undefined;

    try {
      offlineIndex = await declarationOfflineService.createDanger(newDangerGrave);
      offlineDeclaration = await declarationOfflineService.getDangerGrave(offlineIndex);
    } catch (error) {
      console.error(error);
      offlineFailed = true;
      newDangerGrave.id = Guid.create().toString();
    }

    try {
      await dangerService.create(offlineDeclaration ?? newDangerGrave);
      if (offlineIndex !== undefined) {
        void declarationOfflineService.removeDangerById(offlineIndex);
      }
      enqueueSnackbar(ToastMessages.SUCCESS_CREATE_REDIRECT, {
        variant: "success",
      });
      navigate(`/`);
    } catch (error) {
      if (!offlineFailed) {
        enqueueSnackbar(ToastMessages.WARNING_CREATE_OFFLINE, {
          variant: "warning",
        });
        navigate(`/`);
      } else {
        console.error("Offline save failed !", error);
        enqueueSnackbar(ToastMessages.ERROR_CREATE_OFFLINE, {
          variant: "error",
        });
      }
    }
  }

  function onFormValidationError(_errors: object, _event?: BaseSyntheticEvent): void {
    enqueueSnackbar(ToastMessages.ERROR_FORM_VALIDATION, {
      variant: "error",
    });
  }

  return (
    <Grid
      component="form"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>, onFormValidationError)}
      container
      spacing={3}>
      <Grid item xs={12}>
        <Typography variant="caption" sx={{ color: grey[500] }}>
          Les champs suivis d'un astérisque ( * ) sont obligatoires
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledDateTime
          name="dateDanger"
          control={control}
          defaultValue={dangerGrave?.dateEtHeure ?? new Date()}
          rules={{
            required: "Veuillez renseigner une date valide",
            validate: (value: Date) =>
              !dateUtil.isFuture(value) || "Veuillez renseigner une date valide",
          }}
          label="Date de l'événement *"
          readOnly={isFormReadonly}
          maxDate={new Date()}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledTimePicker
          name="heureDanger"
          control={control}
          defaultValue={dangerGrave?.dateEtHeure ?? null}
          rules={{
            required: "Veuillez renseigner l'heure de l'événement.",
            validate: (value: Date) => {
              if (!dateUtil.isValid(value)) {
                return "Veuillez renseigner une heure valide.";
              }
              let comparisonDate: Date | string;
              if (dateUtil.isValid(getValues("dateDanger"))) {
                const date = dateUtil.getDate(getValues("dateDanger"));
                const time = dateUtil.getDate(value);
                comparisonDate = dateUtil.composeFromSeparateDateAndTime(date, time);
              } else {
                comparisonDate = value;
              }
              return (
                !dateUtil.isFuture(comparisonDate) ||
                "L'heure de l'événement ne peut pas être dans le futur."
              );
            },
          }}
          label="Heure de l'événement *"
          readOnly={isFormReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSectionTitle title="Lieu de l'événement" infoKey="" />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="rue"
          control={control}
          defaultValue={dangerGrave?.rue ?? ""}
          rules={{ required: "Ce champ est obligatoire" }}
          label="Rue ou description du lieu *"
          placeholder="Rue ou description du lieu"
          readOnly={isFormReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="communeInsee"
          control={control}
          rules={{ required: "Ce champ est obligatoire" }}
          render={({ field: { onChange, ref }, fieldState: { error } }) => (
            <CommuneSelect
              onChange={onChange}
              setCommuneName={(value: string) => {
                setValue("communeName", value);
              }}
              inputRef={ref}
              communes={data.communes}
              defaultValue={dangerGrave?.communeInsee}
              defaultName={dangerGrave?.communeName}
              error={error != null}
              readOnly={isFormReadonly}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledOuvrageTypeSelect
          name="ouvrageType"
          control={control}
          defaultValue={dangerGrave?.ouvrageType}
          label="Type d'ouvrage"
          readOnly={isFormReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <OuvrageSelect
          communeINSEE={watch("communeInsee") ?? ""}
          typeOuvrage={watch("ouvrageType") ?? ""}
          allOuvrages={data.ouvrages}
          notOuvrageTypeString="NON"
          defaultOuvrage={
            dangerGrave?.ouvragePosteTechnique != null && dangerGrave?.ouvrageDescription !== ""
              ? {
                  posteTechnique: dangerGrave.ouvragePosteTechnique,
                  designation: dangerGrave.ouvrageDescription,
                  typeObjet: "",
                  rue: "",
                }
              : undefined
          }
          setPosteTechnique={(s: string) => {
            setValue("ouvragePosteTechnique", s);
          }}
          setOuvrageDescription={(s: string) => {
            setValue("ouvrageDescription", s);
          }}
          readOnly={isFormReadonly}
          error={errors.ouvragePosteTechnique != null || errors.ouvrageDescription != null}
          helperText={errors.ouvragePosteTechnique?.message as string}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="territoire"
          control={control}
          defaultValue={dangerGrave?.territoire}
          label="Territoire de l'événement si connu"
          readOnly={isFormReadonly}
          select
          options={data.territoires}
        />
      </Grid>
      <Grid item xs={12}>
        <FormSectionTitle title="Description" infoKey="" />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="description"
          control={control}
          rules={{ required: "Ce champ est obligatoire" }}
          defaultValue={dangerGrave?.description ?? ""}
          label="Description lié à l'événement *"
          placeholder="Ma description..."
          readOnly={isFormReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <ControlledTextField
          name="commentaire"
          control={control}
          defaultValue={dangerGrave?.commentaire ?? ""}
          label="Commentaire lié à l'événement"
          placeholder="Mon commentaire..."
          readOnly={isFormReadonly}
        />
      </Grid>
      <Grid item xs={12}>
        <DividerTitle>
          <Typography variant="h5">Photos</Typography>
        </DividerTitle>
      </Grid>
      <Grid item xs={12} marginBottom={4}>
        <Controller
          name="base64Photos"
          control={control}
          render={({ field: { onChange } }) => (
            <PhotosInput
              value={dangerGrave?.base64Photos ?? []}
              onChange={onChange}
              maximumPhotos={3}
              readOnly={!isNew}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="confirmation"
          control={control}
          defaultValue={false}
          rules={{ required: "Ce champ est obligatoire" }}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <FormControl fullWidth error={error != null}>
              <FormControlLabel
                sx={{ textAlign: "justify" }}
                label="Je certifie sur l'honneur ne pas pouvoir mettre en place des actions qui pourraient supprimer le risque ou diminuer sensiblement sa potentielle gravité (risque résiduel non mortel permettant de garantir l'intégrité physique et mentale). *"
                control={<Checkbox checked={value} onChange={onChange} />}
                labelPlacement="end"
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="confirmation2"
          control={control}
          defaultValue={false}
          rules={{ required: "Ce champ est obligatoire" }}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <FormControl fullWidth error={error != null}>
              <FormControlLabel
                sx={{ textAlign: "justify" }}
                label="Je certifie sur l'honneur être en situation de danger grave et imminent et souhaite exercer mon droit de retrait. J'ai informé ma ligne hiérarchique en amont de cette déclaration afin de m'être assuré qu'il n'existait pas à leurs connaissances non plus de moyen de travailler en sécurité. *"
                control={<Checkbox checked={value} onChange={onChange} />}
                labelPlacement="end"
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="isConfidentiel"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <FormControl fullWidth error={error != null}>
              <FormControlLabel
                sx={{ textAlign: "justify" }}
                label={DeclarationConfidentielleCheckboxLabel}
                control={<Checkbox checked={value} onChange={onChange} />}
                labelPlacement="end"
              />
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <LoadingButton
          loading={disableSending}
          endIcon={<SaveIcon />}
          disabled={isFormReadonly || disableSending}>
          Enregistrer
        </LoadingButton>
      </Grid>
    </Grid>
  );
}

export default DangerGraveForm;
