export interface Risque {
  index: number;
  label: string;
  value: string;
  active: boolean;
}

const risquesSecurites: Array<Risque> = [
  {
    active: true,
    index: 1,
    label:
      "Risques liés à l'ambiance de travail (températures, éclairage, conditions climatiques…)",
    value:
      "Risques liés à l'ambiance de travail (températures, éclairage, conditions climatiques…)",
  },
  {
    active: true,
    index: 2,
    label:
      "Risques liés aux équipements de travail (outillages, machine - engin - Renversement d'engin)",
    value:
      "Risques liés aux équipements de travail (outillages, machine - engin - Renversement d'engin)",
  },
  {
    active: true,
    index: 3,
    label: "Risques liés à la circulation interne engin - piéton",
    value: "Risques liés à la circulation interne engin - piéton",
  },
  {
    active: true,
    index: 4,
    label: "Risques liés à la pression - Elément sous contrainte)",
    value: "Risques liés à la pression - Elément sous contrainte)",
  },
  {
    active: true,
    index: 5,
    label: "Risques liés à la chute de hauteur ",
    value: "Risques liés à la chute de hauteur ",
  },
  {
    active: true,
    index: 6,
    label: "Manutention manuelle - Gestes répétitifs (TMS)",
    value: "Manutention manuelle - Gestes répétitifs (TMS)",
  },
  {
    active: true,
    index: 7,
    label: "Manutention mécanique",
    value: "Manutention mécanique",
  },
  {
    active: true,
    index: 8,
    label: "Travail sur écran (Méthode - Ergonomie - Cadence - Ecran)",
    value: "Travail sur écran (Méthode - Ergonomie - Cadence - Ecran)",
  },
  {
    active: true,
    index: 9,
    label: "Chaleur - Point chaud (soudure, meulage, tronçonnage, Brûlures thermiques)",
    value: "Chaleur - Point chaud (soudure, meulage, tronçonnage, Brûlures thermiques)",
  },
  {
    active: true,
    index: 10,
    label: "Risque chimique - Substance dangereuse (Stockage - Utilisation - Exposition)",
    value: "Risque chimique - Substance dangereuse (Stockage - Utilisation - Exposition)",
  },
  {
    active: true,
    index: 11,
    label: "Risques psychosociaux",
    value: "Risques psychosociaux",
  },
  {
    active: true,
    index: 12,
    label: "Risque lié à l'ensevelissement (Tranchée - Gallerie - Terrain meuble)",
    value: "Risque lié à l'ensevelissement (Tranchée - Gallerie - Terrain meuble)",
  },
  {
    active: true,
    index: 13,
    label: "Risque lié à la Coactivité",
    value: "Risque lié à la Coactivité",
  },
  { active: true, index: 14, label: "Anoxie (manque 02)", value: "Anoxie (manque 02)" },
  { active: true, index: 15, label: "Risque biologique ", value: "Risque biologique " },
  { active: true, index: 16, label: "Piqûre - Morsure", value: "Piqûre - Morsure" },
  {
    active: true,
    index: 17,
    label: "Risque de coupure, heurt, choc (Blessures corporels)",
    value: "Risque de coupure, heurt, choc (Blessures corporels)",
  },
  {
    active: true,
    index: 18,
    label: "Risque de brûlure thermique (projection de matière..)",
    value: "Risque de brûlure thermique (projection de matière..)",
  },
  {
    active: true,
    index: 19,
    label:
      "Risque lié à l'écrasement (machine, presses, cisailles, cintreuses, engrenages, poulies, courroies)",
    value:
      "Risque lié à l'écrasement (machine, presses, cisailles, cintreuses, engrenages, poulies, courroies)",
  },
  {
    active: true,
    index: 20,
    label: "Risques routiers (trajet, intervention sur chaussée, déplacement professionnel)",
    value: "Risques routiers (trajet, intervention sur chaussée, déplacement professionnel)",
  },
  {
    active: true,
    index: 21,
    label: "Risque de chute de plain-pied",
    value: "Risque de chute de plain-pied",
  },
  {
    active: true,
    index: 22,
    label: "Risque Incendie - Explosion",
    value: "Risque Incendie - Explosion",
  },
  { active: true, index: 23, label: "Risque électrique ", value: "Risque électrique " },
  {
    active: true,
    index: 24,
    label: "Risques liés aux chutes d'objet - Effondrement",
    value: "Risques liés aux chutes d'objet - Effondrement",
  },
  { active: true, index: 25, label: "Risque de Noyade", value: "Risque de Noyade" },
  {
    active: true,
    index: 26,
    label: "Risques liés aux rayonnements non ionisant",
    value: "Risques liés aux rayonnements non ionisant",
  },
  {
    active: true,
    index: 27,
    label: "Risques et nuisances liés au bruit (surdité)",
    value: "Risques et nuisances liés au bruit (surdité)",
  },
  {
    active: true,
    index: 28,
    label: "Risque lié à la vibration",
    value: "Risque lié à la vibration",
  },
  {
    active: true,
    index: 29,
    label: "Organisation du travail (télétravail - horaires atypiques..)",
    value: "Organisation du travail (télétravail - horaires atypiques..)",
  },
  {
    active: true,
    index: 30,
    label: "Risques liés au problème de santé",
    value: "Risques liés au problème de santé",
  },
  { active: false, index: 9999, label: "Autres", value: "Autres" },
];

export default risquesSecurites;
